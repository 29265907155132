<template>
  <div class="public">
    <el-button @click="fileDownload" size="mini" type="primary">下载当前文件</el-button>
    <vue-office-docx v-if="docx" :src="docx" />
    <vue-office-pdf v-if="pdf" :src="pdf" />
    <vue-office-excel v-if="xlsx" :src="xlsx" />
    <img style="width: 100vw;" v-if="img" :src="img" alt="" srcset="">
  </div>
</template>


<script>
//引入VueOfficeExcel组件
import VueOfficeExcel from '@vue-office/excel'
//引入相关样式
import '@vue-office/excel/lib/index.css'
import VueOfficeDocx from '@vue-office/docx'
import VueOfficePdf from '@vue-office/pdf'
export default {
  name: 'Public',
  components: {
    VueOfficeExcel,
    VueOfficeDocx,
    VueOfficePdf
  },
  data() {
    return {
      xlsx: '',
      docx: '',
      pdf: '',
      img: ''
    }
  },
  methods: {
    rendered() {
      console.log("渲染完成")
    },
    fileDownload() {

      if (this.xlsx) {
        location.href = this.xlsx;
        return

      }
      if (this.docx) {
        location.href = this.docx;
        return

      }
      if (this.pdf) {
        location.href = this.pdf;
        return

      }
      if (this.img) {
        location.href = this.img;
        return

      }
    },
  },
  created() {
    if (this.$route.query.url.includes('png') || this.$route.query.url.includes('jpg') || this.$route.query.url.includes('jpeg') || this.$route.query.url.includes('bmp')) {
      this.img = this.$baseUrl +
        'api/file/fileDownloadCommon.do?path=' + this.$route.query.url
    }
    if (this.$route.query.url.includes('xlsx')) {
      this.xlsx = this.$baseUrl +
        'api/file/fileDownloadCommon.do?path=' + this.$route.query.url

    }
    if (this.$route.query.url.includes('docx')) {
      this.docx = this.$baseUrl +
        'api/file/fileDownloadCommon.do?path=' + this.$route.query.url

    }
    if (this.$route.query.url.includes('pdf')) {
      this.pdf = this.$baseUrl +
        'api/file/fileDownloadCommon.do?path=' + this.$route.query.url

    }
  }
}
</script>


<style lang="scss" scoped>
.public {
  width: 100vw;
  height: 100vh;
}

.el-button {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 1000 !important;
}
</style>
